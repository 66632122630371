import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FinnJob, Job } from '../../../../shared/models/job/job.model';
import { Company, FinnAdSetting } from '../../../../shared/models/company/company.model';

import { environment } from '../../../../../environments/environment';
import { FinnIndustry, FinnOccupation, FinnPartnerId, FinnStatics } from '../models/finn.model';
import { getUserRole, USER_ROLE } from '../../../helpers/roles';
import { User } from '../../../../shared/models/user/user.model';
import { ErrorHandlingService } from '../../../services/error-handling/error-handling.service';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';



export interface FinnMessage {
    message: string;
    level: string;
    name: string;
    value: string;
}

export interface FinnResponse {
    data: FinnJob;
    messages: FinnMessage[];
}

@Injectable()
export class FinnService {
    private apiUrl = environment.apiUrl;
    private entityUrl = this.apiUrl + 'finn/';

    constructor(
        private readonly http: HttpClient,
        private readonly errorService: ErrorHandlingService,
        private readonly translate: TranslateService
    ) { }

    public fetchIndustries(): Observable<FinnIndustry[]> {
        return this.http.get<FinnIndustry[]>(this.entityUrl + 'industries/');
    }

    public fetchStatics(): Observable<FinnStatics> {
        return this.http.get<FinnStatics>(this.entityUrl + 'jobs/statics/');
    }

    public fetchOccupations(): Observable<FinnOccupation[]> {
        return this.http.get<FinnOccupation[]>(this.entityUrl + 'occupations/');
    }

    public publishFinnJob(finnJob: FinnJob, isDraft: 0 | 1 = 0, validateOnly: 0 | 1 = 0): Observable<FinnJob> {
        let params = new HttpParams();

        if (isDraft) {
          params = params.append('draft', `${isDraft}`);
        }

        if (validateOnly) {
          params = params.append('validate', `${validateOnly}`);
        }

        return this.http.put<FinnJob>(this.entityUrl + `jobs/${finnJob.id}/`, finnJob, {params});
    }

    public previewFinnJob(finnJob: FinnJob): Observable<any> {
      return this.http.put<any>(this.entityUrl + `jobs/${finnJob.id}/?preview=1`, finnJob);
    }

    public prepareFinnJob(jobId: number): Observable<FinnJob> {
        return this.http.post<FinnJob>(this.entityUrl + 'jobs/', {
            job_id: jobId,
        });
    }

    public fetchFinnJob(jobId: number): Observable<FinnJob> {
        return this.http.get<FinnJob>(this.entityUrl + `jobs/by-cvideo-job/${jobId}/`);
    }

    public deactivateFinnJob(finnJobId: number): Observable<FinnJob> {
        return this.http.delete<FinnJob>(this.entityUrl + `jobs/${finnJobId}/`);
    }

    public getFinnJobs(companyId: number): Observable<FinnJob[]> {
        let params = new HttpParams();
        params = params.append('company_id', `${companyId}`);

        return this.http.get<FinnJob[]>(this.entityUrl + `jobs/`, {
          params
        });
    }

    deleteFinnJobs(jobsIds: number[]): Observable<any> {
      return this.http.delete(this.entityUrl + `jobs/`, {
        body: {
          finn_job_ids: jobsIds
        }
      });
    }

    public createPartnerId(companyId: number, partnerId: FinnPartnerId): Observable<any> {
      return this.http.post<FinnJob>(`${this.entityUrl}${companyId}/partner-id/`, partnerId);
    }

    public deletePartnerId(companyId: number, partnerId: number): Observable<any> {
      return this.http.delete(`${this.entityUrl}${companyId}/partner-id/${partnerId}/`);
    }

    public updatePartnerId(companyId: number, partnerId: number, partnerIdObj: FinnPartnerId): Observable<any> {
      return this.http.put(`${this.entityUrl}${companyId}/partner-id/${partnerId}/`, partnerIdObj);
    }

    isFinnIntegrationVisible(user: User, company: Company): boolean {
      if (!user || !company) {
        return false;
      }

      const hasRight = getUserRole(user) === USER_ROLE.ADMIN || getUserRole(user) === USER_ROLE.RECRUITER;
      const properLanguage = environment.finnIntegrationVisibleLanguages.includes(company.default_language);

      return hasRight && properLanguage;
    }

    getFinnAdvancedSettings(ads: FinnAdSetting[]): any {
      if (!ads) {
        return {};
      }

      const restructuredObject = {};

      ads.forEach(ad => {
        const { partner_id, logo, company_name, about_us } = ad;
        restructuredObject[partner_id] = { logo, company_name, about_us };
      });

      return restructuredObject;
    }

    canFinnBePublished(finnJob: FinnJob): boolean {
      if (!finnJob) {
        return false;
      }

      const requiredFields = ['title', 'header', 'industries', 'country', 'zip_code', 'duration',
        'occupations', 'advert_start_date', 'job_type'];

      return requiredFields.every(field => !!finnJob[field]) &&
        !finnJob.job_contacts.find(contact => !contact.name);
    }

    logUnexpectedError(details: {
      user: User,
      company: Company,
      job: Job,
      error: HttpErrorResponse,
      finnJob: FinnJob
    }): void {
      const info = {
        user: `${details.user?.first_name} ${details.user?.last_name} (id: ${details.user?.id})`,
        company: `${details.company?.name} (id: ${details.company?.id})`,
        job: `${details.job?.title} (id: ${details.job?.id})`,
        status: details.error?.status,
        error: details.error?.error,
        finnJob: details.finnJob
      };

      const title = 'Unexpected error response when publishing Finn job. User experiences general \'Smth went wrong\' message.';
      const text = JSON.stringify(info, null, 2);

      this.errorService.logMessageToSlack({ title, text });
    }

    handleContactNameError(params: {
      form: FormGroup,
      field: string,
      message: string,
      scrollableBlock: ElementRef
    }): void {
      const { form, field, message, scrollableBlock } = params;

      if (field.includes('job_contacts')) {
        form.setErrors({ job_contacts: message });
        this.errorService.scrollToError(scrollableBlock);
      } else {
        this.errorService.showErrorToast(`${this.translate.instant('COMMON.VALIDATION_ERROR')} ${field}: ${this.translate.instant(message)}`);
      }
    }

}
